import React, { useEffect, useState } from "react"
import Seo from "../../components/seo/seo"
import LayoutDiagnostic from "../../components/layoutDiagnostic/layoutDiagnostic"
import ButtonStandard from "../../components/buttonStandard/buttonStandard"
import { graphql } from "gatsby"
import utils from "../../utils/utils"
import "./index.scss"
import { getHourly, getTaxoLien } from "../../hooks/hooks"
import tracking from "../../utils/tracking"
import diagnostics from "../../utils/diagnostics"
import { LocalStorage } from "ttl-localstorage"
import links from "../../utils/links"

export const query = graphql`
  query {
    nodeRappel {
      field_rappel_intro {
        value
      }
      field_rappel_outro {
        value
      }
    }
  }
`

const index = ({ data = null }) => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  useEffect(() => {
    setIsMobile(utils.isMobileSize())
    window.addEventListener("resize", () => {
      setIsMobile(utils.isMobileSize())
    })
  }, [])
  const numPhone = getTaxoLien("numero telephone")
  const hourly = getHourly("horaire_service_client")
  const dataCall = data
    ? {
        intro: data.nodeRappel.field_rappel_intro.value,
        outro: data.nodeRappel.field_rappel_outro.value,
      }
    : null
  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "ineligibilitypage")
    tracking.track("page_name", "")
    tracking.track("categorie", "telesurveillance")
    tracking.trackDiagInfo("click.navigation", "/diagnostic/ineligibilite" + (isTherecampagne ? "/" + campagne : ""))
    tracking.trackInit()
    utils.removeLocalDiag()
  }, [])
  return (
    <LayoutDiagnostic dataCall={dataCall}>
      <Seo title={"Inéligibilité"} linkCanonical={"inegebilite"} />
      <div className={(isMobile ? "centrerMobile " : "") + "wrapper bc-md-w pt-5 pt-md-0"}>
        <div className={(isMobile ? "w-95 " : "") + "d-f fd-c jc-c ai-c"}>
          <div className="ineligibility-container box-shadow-m bc-w br-22 d-f fd-c ai-c jc-c">
            <div
              style={{ width: "60px", height: "60px" }}
              className="icon-house-color mb-md-2"
            ></div>
            <h1 className={(isMobile ? "w-80 " : "") + " fs-24 lh-32 ff-gb ta-c "}>
              Votre logement est unique !{" "}
            </h1>
            <p className={(isMobile ? "w-90 " : "") + "fs-16 lh-24 ta-c"}>
              Un expert vous contactera dans les 24h afin d’effectuer une étude
              personnalisée de votre logement
            </p>
            <div className={(isMobile ? " w-90" : " w-100") + " br-12 py-2 px-2 bc-2 d-f  ai-c mb-3 d-md-f fd-md-c jc-md-c ai-md-c ta-md-c"}>
              <div
                style={{ height: "55px", minWidth: "55px" }}
                className="icon-user-call mr-3 mr-md-0 mb-md-1"
              ></div>
              <div className="d-f fd-c">
                <p className="c-b mb-0 fs-14 lh-24  mb-0">
                  Vous pouvez également contacter votre conseiller directement :
                </p>
                <p className="c-b mb-0 fs-14 lh-24  mb-0">
                  {" "}
                  au {numPhone} du lundi au vendredi, de{" "}
                  {hourly ? hourly.hourlyStart : ""} à{" "}
                  {hourly ? hourly.hourlyEnd : ""}
                </p>
              </div>
            </div>
            {/**
             *   <div className=" w-100 bcg-2 br-12 py-1 px-3 d-f fd-c ai-c jc-c">
              <p className="fs-14 cg-7 lh-24 mb-0 ff-gbook">
                Vous pouvez également contacter votre conseiller directement :{" "}
              </p>
              <p className="cg-7 fs-16  lh-24 mb-0 ">{}</p>
              <p className="cg-7 fs-14 lh-24 mb-0 ff-gbook">
                du lundi au vendredi, de {hourly ? hourly.hourlyStart : ""} à{" "}
                {hourly ? hourly.hourlyEnd : ""}
              </p>
            </div>
             * 
             */}
          </div>
        </div>
      </div>
      <div className="d-f fd-c jc-c ai-c mt-5 mb-5 mt-md-3">
        <ButtonStandard
          url={links.getLink("home") + campagne}
          addClass="with-line c-1 fs-14 accueil-btn"
          label="Retour à la page d’accueil"
        />
      </div>
    </LayoutDiagnostic>
  )
}

export default index
